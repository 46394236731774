import React from "react"
import { Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const AGB = () => (
  <Layout>
    <Container>
      <SEO title="Impressum" />
      <h2>Impressum</h2>
      <br />
      <table>
        <tbody>
          <tr>
            <td className="text">
              <p>
                <br />
                <strong>Pflichtangaben nach § 5 TMG</strong>
                <br />
                <br />
                zooplus AG
                <br />
                Sonnenstraße 15
                <br />
                80331 München
                <br />
                <br />
                Tel: +49 89 211 29 211 (Mo. bis Fr. 8:00 - 19:00 Uhr, Sa. 8:00 -
                14:00 Uhr)
                <br />
                <br />
                E-Mail:{" "}
                <a href="mailto:service@zooplus.de">service@zooplus.de</a>
              </p>
              <p>
                Sitz der Gesellschaft: München
                <br />
                Registergericht: Amtsgericht München
                <br />
                Handelsregisternummer: HRB 125080
                <br />
                USt-Id-Nr. DE 200 164 421
              </p>
            </td>
          </tr>
          <tr>
            <td className="text">
              <p>
                <br />
                Vorstand
                <br />
                <br />
                Dr. Cornelius Patt
                <br />
                Andreas Maueröder
                <br />
                Dr. Mischa Ritter
                <br />
                Florian Welz
              </p>
              <p>
                Vorsitzender des Aufsichtsrats:
                <br />
                Christian Stahl
              </p>
            </td>
          </tr>
          <tr>
            <td className="text">
              <br />
              Copyright
              <br />
              <br />
              Das Zeichen "zooplus" ist markenrechtlich geschützt.
              <br />
              <br />
              Alle Inhalte sind urheberrechtlich geschützt. Wir weisen darauf
              hin, dass die Verwendung von Bildern, Texten oder Teilen daraus in
              gedruckten oder Online-Publikationen der schriftlichen Zustimmung
              der zooplus AG bedarf.
              <br />
              <br />
              Alle Inhalte sind nur für die persönliche Information bestimmt.
              Jede weiter gehende kommerzielle oder nicht kommerzielle
              Verwendung, insbesondere die Speicherung in Datenbanken,
              Veröffentlichung, Vervielfältigung und jede Form von gewerblicher
              Nutzung sowie die Weitergabe an Dritte - auch in Teilen oder in
              überarbeiteter Form - ohne Zustimmung der Rechteinhaber ist
              untersagt. Die in der Quellenangabe angegebenen Urheber sind für
              die jeweiligen Inhalte verantwortlich. Die Verwendung von
              Warenzeichen bzw. Markennamen, insbesondere in
              Produktbeschreibungen des Shoppingbereiches, erfolgt unter
              Anerkennung der Rechte der jeweiligen Rechteinhaber. Die
              Verwendung automatischer Mechanismen (Suchmaschinen, Robots,
              Crawler), beispielsweise zur Bedienung von Produkt- oder
              Preissuchmaschinen, ist ebenso genehmigungspflichtig wie
              Deep-Linking auf Inhalts- bzw. Produktebene, sowie das Anzeigen
              von zooplus-Content innerhalb von Frame-Seiten.
              <br />
              &nbsp;
            </td>
          </tr>
          <tr>
            <td className="btitle">
              <a href="https://www.zooplus.de/contact_us">Kundenservice</a>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br />
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) zwischen Unternehmern und Verbrauchern
        bereit, die Sie hier finden:{" "}
        <a href="//ec.europa.eu/consumers/odr/">
          www.ec.europa.eu/consumers/odr.
        </a>{" "}
        Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht
        bereit.
      </p>
    </Container>
  </Layout>
)

export default AGB
